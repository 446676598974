// Angular imports
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

// Third-party libraries imports
import { Observable } from 'rxjs';

// App own modules and services imports
import { ApiService } from '@core/services';
// Intefaces inmports
import { IUser, IResponse } from '@shared/interfaces';
import { log } from 'console';

interface TokenUser {
  token: string;
  user: IUser;
}
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _user: IUser = {} as IUser;

  constructor(private jwtHelper: JwtHelperService, private api: ApiService<TokenUser>) {}

  isAuthenticated(): boolean {
    return Object.keys(this._user).length > 0;
  }

  hasValidToken(): boolean {
    const token = localStorage.getItem('token');
    console.log("ALASDHF", token);
    
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }

  authenticateUser(username: string, password: string): Observable<IResponse<TokenUser>> {
    return this.api.create('users/authenticate', { username, password }, 'admin');
  }

  validateToken(): Observable<IResponse<TokenUser>> {
    return this.api.read('users/token');
  }

  set user(user: IUser) {
    this._user = user;
  }

  get user(): IUser {
    return this._user;
  }

  logout(): void {
    localStorage.removeItem('token');
    this._user = {} as IUser;
  }

  /**
   * Returns the role of the user of the contract indicated by parameter.
   * If there is no user contract it returns null and if it is a global administrator of the app, returns admin
   * @param idContract id contract number
   * @returns admin, manager_admin, manager, client or null
   */
  getRoleByContract(idContract: number): string | null {
    if (this.user.isAdmin) {
      return 'admin';
    }
    const contract = this._user.contracts.find((contractFind) => contractFind.id === idContract);
    return contract?.userContract.role ?? null;
  }

  /**
   * Checks if the user has permissions to access the specified feature in the active contract and module received via parameter from the URL.
   * @param contractId active contract id
   * @param moduleId active module id
   * @param featureId active feature id
   * @returns True if the user has permissions, otherwise false.
   */
  hasPermissions(contractId: number, moduleId: number, featureId: number): boolean {
    let hasModuleAccess = true;
    let hasFeatureRemoved = false;
    const activeContract = this.user.contracts.find((contract) => contract.id === contractId);

    if (activeContract) {
      hasModuleAccess = activeContract.modules.some((module) => module.moduleId === moduleId);
    }

    // Find the feature with the specified featureId and moduleId in the user's features
    const featureRemoved = this._user.features.find(
      (feature) => feature.id === featureId && feature.moduleId === moduleId,
    );
    // Check if the feature was found and if it was removed for the active contract
    if (featureRemoved) {
      hasFeatureRemoved = featureRemoved.userContractRemovedFeature.some(
        (feature: { contractId: number }) => feature.contractId === contractId,
      );
    }

    return hasModuleAccess && !hasFeatureRemoved;
  }
}
