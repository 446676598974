import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardGuard implements CanActivate {
  constructor(private _auth: AuthService, private _router: Router) {}

  // canActivate(): boolean {
  //   if (this._auth.isAuthenticated()) {
  //     return true;
  //   } else {
  //     this._router.navigate(['auth/login']);
  //     return false;
  //   }
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this._auth.isAuthenticated()) {
      return true;
    } else {
      this._router.navigate(['auth', 'login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
  }
}
