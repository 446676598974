// Angular imports
import { HttpClient, HttpClientModule, HttpErrorResponse } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

// Module inner imports
import { AuthService, ContextService } from '@core/services';
import { UserService } from '@shared/services';

// Third-party libraries imports
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// Register spanish localization
registerLocaleData(localeEs, 'es');

// export function appInitializer(auth: AuthService): () => Promise<any> {
//   return () =>
//     new Promise((resolve: any) =>
//       auth
//         .validateToken()
//         .subscribe({
//           next: (response) => {
//             localStorage.setItem('token', response.result.token);
//             auth.user = response.result.user;
//           },
//           error: (error: HttpErrorResponse) => {
//             localStorage.clear();
//             document.cookie = `Authorization=; Path=/; Domain=${window.location.hostname};Max-Age=0; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
//             console.error('error appInitializer(): ', error);
//           },
//         })
//         .add(resolve),
//     );
// }

@NgModule({
  declarations: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'es',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  exports: [BrowserModule, BrowserAnimationsModule],
  providers: [
    UserService,
    ContextService,
    {
      provide: JwtHelperService,
      useFactory: () => new JwtHelperService(),
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: appInitializer,
    //   deps: [AuthService],
    //   multi: true,
    // },
    {
      provide: LOCALE_ID,
      useValue: 'es',
    },
  ],
})
export class CoreModule {}
