// Angular imports
import { Injectable } from '@angular/core';

// Third-party libraries imports
import { Observable } from 'rxjs';

// App own modules and services
import { ApiService } from '@core/services/api.service';
import { IUser, IResponse } from '@shared/interfaces';

@Injectable()
export class UserService {
  private path = 'users';

  constructor(private api: ApiService<IUser>) {}

  listUsers(): Observable<IResponse<IUser[]>> {
    return this.api.list(this.path);
  }

  readUser(id: string): Observable<IResponse<IUser>> {
    return this.api.read(this.path, id);
  }

  createUser(body: object): Observable<IResponse<IUser>> {
    return this.api.create(this.path, body);
  }

  updateUser(id: string, body: object): Observable<IResponse<IUser>> {
    return this.api.update(this.path, id, body);
  }

  deleteUser(id: string): Observable<IResponse<IUser>> {
    return this.api.delete(this.path, id);
  }
}
