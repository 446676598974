// Angular imports
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// App own modules and services
import { AuthGuard, DashboardGuard } from '@core/guards';

// Module inner imports
import { AppComponent } from './app.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'dashboard' },
  {
    path: 'auth',
    loadChildren: () => import('@features/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () => import('@features/dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [DashboardGuard], // Desactivar para Login real
  },
  { path: '**', redirectTo: 'dashboard' }, // Manejo de rutas desconocidas
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
