// Angular imports
import { Injectable } from '@angular/core';

// Third-party libraries imports
import { BehaviorSubject } from 'rxjs';

// App own modules and services
import { IUserContract } from '@shared/interfaces';

@Injectable()
export class ContextService {
  //TODO: If you comment this property to use the mock it is important that you uncomment it before uploading
  private contract$: BehaviorSubject<IUserContract> = new BehaviorSubject<any>(null);

  // private contract$: BehaviorSubject<IUserContract> = new BehaviorSubject<any>({
  //   id: 19,
  //   name: 'Ayuntamiento Guadalajara',

  //   description: 'Piloto NB IoT Guadalajara',
  //   customer: 'Piloto NB IoT Guadalajara',
  //   customerName: 'Piloto NB IoT Guadalajara',
  //   customerContact: '123123123',
  //   technicalManager: 'Piloto NB IoT Guadalajara',
  //   location: 'Piloto NB IoT Guadalajara',
  //   dateInit: '2023-02-10',
  //   dateEnd: '2023-02-11',
  //   lastRenewalDate: '2023-02-10',
  //   state: true,
  //   customerEmail: 'guadalajara@gmail.com',
  //   users: [
  //     {
  //       role: 'user',
  //       id: 49,
  //       email: 'jcamaraf@serveo.com',
  //       name: 'Jose',
  //       surnames: 'Camara',
  //       phone: '123123123',
  //       company: 'Serveo',
  //       isAdmin: false,
  //       position: 'Serveo',
  //       userContract: {
  //         userId: 49,
  //         contractId: 19,
  //         role: 'manager',
  //         sendAnomaliesMail: false,
  //       },
  //     },
  //     {
  //       role: 'user',
  //       id: 44,
  //       email: 'alberto.diez@bosonit.com',
  //       name: 'Alberto',
  //       surnames: 'Díez Frías',
  //       phone: '123456789',
  //       company: 'Elliot',
  //       isAdmin: false,
  //       position: 'Developer',
  //       userContract: {
  //         userId: 44,
  //         contractId: 19,
  //         role: 'client',
  //         sendAnomaliesMail: true,
  //       },
  //     },
  //     {
  //       role: 'user',
  //       id: 31,
  //       email: 'cliente@serveo.com',
  //       name: 'Usuario cliente',
  //       surnames: 'cliente',
  //       phone: '677785455',
  //       company: 'Serveo',
  //       isAdmin: false,
  //       position: 'Cliente',
  //       userContract: {
  //         userId: 31,
  //         contractId: 19,
  //         role: 'client',
  //         sendAnomaliesMail: false,
  //       },
  //     },
  //     {
  //       role: 'user',
  //       id: 42,
  //       email: 'jsutilfer@gmail.com',
  //       name: 'Jonathan',
  //       surnames: 'Sutil',
  //       phone: '633333333',
  //       company: 'Serveo',
  //       isAdmin: false,
  //       position: 'Pruebas',
  //       userContract: {
  //         userId: 42,
  //         contractId: 19,
  //         role: 'manager',
  //         sendAnomaliesMail: false,
  //       },
  //     },
  //     {
  //       role: 'user',
  //       id: 30,
  //       email: 'aitor@gmail.com',
  //       name: 'Aitor',
  //       surnames: 'Sobera',
  //       phone: '111111111',
  //       company: 'ElliotCloud',
  //       isAdmin: false,
  //       position: 'Developer',
  //       userContract: {
  //         userId: 30,
  //         contractId: 19,
  //         role: 'manager_admin',
  //         sendAnomaliesMail: false,
  //       },
  //     },
  //     {
  //       role: 'user',
  //       id: 33,
  //       email: 'gestor@serveo.com',
  //       name: 'Usuario gestor',
  //       surnames: 'gestor',
  //       phone: '822292299',
  //       company: 'Serveo',
  //       isAdmin: false,
  //       position: 'Gestor',
  //       userContract: {
  //         userId: 33,
  //         contractId: 19,
  //         role: 'manager',
  //         sendAnomaliesMail: false,
  //       },
  //     },
  //   ],
  //   alarmTypes: [
  //     {
  //       id: 1,
  //       name: 'Salida 1 relé ON',
  //       verticalIdentifier: '1',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 1,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 90,
  //       name: 'Battery mode',
  //       verticalIdentifier: 'issuetype_100',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 90,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 2,
  //       name: 'Salida 1 relé OFF',
  //       verticalIdentifier: '2',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 2,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 3,
  //       name: 'Equipo OFF',
  //       verticalIdentifier: '3',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 3,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 4,
  //       name: 'Equipo ON',
  //       verticalIdentifier: '4',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 4,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 5,
  //       name: 'Señal baja red móvil GSM',
  //       verticalIdentifier: '5',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 5,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 6,
  //       name: 'Sin datos red móvil GPRS OFF',
  //       verticalIdentifier: '6',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 6,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 7,
  //       name: 'Salida 2 relé ON',
  //       verticalIdentifier: '7',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 7,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 8,
  //       name: 'Error comunicación analizador',
  //       verticalIdentifier: '8',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 8,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 9,
  //       name: 'Entrada 1 on',
  //       verticalIdentifier: '9',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 9,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 10,
  //       name: 'Entrada 2 on',
  //       verticalIdentifier: '10',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 10,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 11,
  //       name: 'Entrada 3 on',
  //       verticalIdentifier: '11',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 11,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 12,
  //       name: 'Entrada 4 on',
  //       verticalIdentifier: '12',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 12,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 13,
  //       name: 'Corriente alta con relé OFF fase 1',
  //       verticalIdentifier: '13',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 13,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 14,
  //       name: 'Corriente alta con relé OFF fase 2',
  //       verticalIdentifier: '14',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 14,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 15,
  //       name: 'Corriente alta con relé OFF fase 3',
  //       verticalIdentifier: '15',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 15,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 16,
  //       name: 'Exceso de consumo en OFF',
  //       verticalIdentifier: '17',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 16,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 17,
  //       name: 'Exceso de consumo en ON',
  //       verticalIdentifier: '18',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 17,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 18,
  //       name: 'Factor de potencia bajo',
  //       verticalIdentifier: '19',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 18,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 19,
  //       name: 'Sin uso',
  //       verticalIdentifier: '20',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 19,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 20,
  //       name: 'Corriente baja con relé ON fase 1',
  //       verticalIdentifier: '21',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 20,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 21,
  //       name: 'Corriente baja con relé ON fase 2',
  //       verticalIdentifier: '22',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 21,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 22,
  //       name: 'Corriente baja con relé ON fase 3',
  //       verticalIdentifier: '23',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 22,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 23,
  //       name: 'Corriente alta con relé ON fase 1',
  //       verticalIdentifier: '24',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 23,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 24,
  //       name: 'Corriente alta con relé ON fase 2',
  //       verticalIdentifier: '25',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 24,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 25,
  //       name: 'Corriente alta con relé ON fase 3',
  //       verticalIdentifier: '26',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 25,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 26,
  //       name: 'Tensión alta fase 1',
  //       verticalIdentifier: '27',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 26,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 27,
  //       name: 'Tensión baja fase 1',
  //       verticalIdentifier: '28',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 27,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 28,
  //       name: 'Tensión alta fase 2',
  //       verticalIdentifier: '29',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 28,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 29,
  //       name: 'Tensión baja fase 2',
  //       verticalIdentifier: '30',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 29,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 30,
  //       name: 'Tensión alta fase 3',
  //       verticalIdentifier: '31',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 30,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 31,
  //       name: 'Tensión baja fase 3',
  //       verticalIdentifier: '32',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 31,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 32,
  //       name: 'Entrada 5 on',
  //       verticalIdentifier: '33',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 32,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 33,
  //       name: 'Entrada 6 on',
  //       verticalIdentifier: '34',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 33,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 34,
  //       name: 'Entrada 7 on',
  //       verticalIdentifier: '35',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 34,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 35,
  //       name: 'Entrada 8 on',
  //       verticalIdentifier: '36',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 35,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 36,
  //       name: 'Salida 3 relé OFF',
  //       verticalIdentifier: '37',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 36,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 37,
  //       name: 'Salida 3 relé ON',
  //       verticalIdentifier: '38',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 37,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 38,
  //       name: 'Error GPRS',
  //       verticalIdentifier: '39',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 38,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 39,
  //       name: 'Error AC In',
  //       verticalIdentifier: '40',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 39,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 40,
  //       name: 'Salida 4 relé OFF',
  //       verticalIdentifier: '41',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 40,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 41,
  //       name: 'Salida 4 relé ON',
  //       verticalIdentifier: '42',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 41,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 42,
  //       name: 'Error tabla relés',
  //       verticalIdentifier: '43',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 42,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 43,
  //       name: 'Error de reloj',
  //       verticalIdentifier: '44',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 43,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 49,
  //       name: 'Desconocido',
  //       verticalIdentifier: '16',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 49,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 50,
  //       name: 'PERFIL_ALARM_EDIFICIOS',
  //       verticalIdentifier:
  //         'Name: PERFIL_ALARM_EDIFICIOS, Contract: San Sebastián de los Reyes, idContract: 14',
  //       deviceType: 'panel',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 50,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 517,
  //       name: 'Light switching failed',
  //       verticalIdentifier: 'issuetype_3750',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 517,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 518,
  //       name: 'Battery not connected',
  //       verticalIdentifier: 'issuetype_2414',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 518,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 520,
  //       name: 'Battery shutdown',
  //       verticalIdentifier: 'issuetype_101',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 520,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 521,
  //       name: 'Main power failure',
  //       verticalIdentifier: 'issuetype_3516',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 521,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 522,
  //       name: 'Undervoltage error phase L1',
  //       verticalIdentifier: 'issuetype_3511',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 522,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 525,
  //       name: 'Undervoltage error phase L3',
  //       verticalIdentifier: 'issuetype_3515',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 525,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 526,
  //       name: 'Undervoltage error phase L2',
  //       verticalIdentifier: 'issuetype_3513',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 526,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 527,
  //       name: 'ABUS overload',
  //       verticalIdentifier: 'issuetype_3201',
  //       deviceType: 'panel',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 527,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 89,
  //       name: 'Ctc_Event_ErrorInGPSCoordinate',
  //       verticalIdentifier: 'Ctc_Event_ErrorInGPSCoordinate',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 89,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 44,
  //       name: 'Tensión alta o máxima',
  //       verticalIdentifier: '1',
  //       deviceType: 'node',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 44,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 45,
  //       name: 'Tensión baja o mínima',
  //       verticalIdentifier: '2',
  //       deviceType: 'node',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 45,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 46,
  //       name: 'Corriente alta o máxima',
  //       verticalIdentifier: '3',
  //       deviceType: 'node',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 46,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 47,
  //       name: 'Corriente baja o mínima',
  //       verticalIdentifier: '4',
  //       deviceType: 'node',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 47,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 48,
  //       name: 'Error GPRS, Error Driver',
  //       verticalIdentifier: '8',
  //       deviceType: 'node',
  //       verticalId: 1,
  //       alarmTypeContract: {
  //         alarmTypeId: 48,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 504,
  //       name: 'Row of lights out (three or more)',
  //       verticalIdentifier: 'IssueCategory_RowOfLightsOut',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 504,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 505,
  //       name: 'Light flashing',
  //       verticalIdentifier: 'IssueCategory_LightFlashing',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 505,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 506,
  //       name: 'Luminaire glass broken',
  //       verticalIdentifier: 'IssueCategory_LuminaireGlassBroken',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 506,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 507,
  //       name: 'Luminaire hanging off',
  //       verticalIdentifier: 'IssueCategory_LuminaireHangingOff',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 507,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 508,
  //       name: 'Door open',
  //       verticalIdentifier: 'IssueCategory_LightPoleDoorOpen',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 508,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 509,
  //       name: 'Pole or Housing damaged',
  //       verticalIdentifier: 'IssueCategory_LightPoleDamaged',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 509,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 510,
  //       name: 'Light pole knocked over',
  //       verticalIdentifier: 'IssueCategory_LightPoleKnockedOver',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 510,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 511,
  //       name: 'Wires exposed, risk of electric shock',
  //       verticalIdentifier: 'IssueCategory_ExposedWires',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 511,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 512,
  //       name: 'Other street lighting fault',
  //       verticalIdentifier: 'IssueCategory_OtherStreetLightingFault',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 512,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 513,
  //       name: 'The current measured is either lower than expected or no current is flowing through the driver-lamp combination.',
  //       verticalIdentifier: 'Event_NoCurrent',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 513,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 514,
  //       name: 'Ctc_ErrorKey_HasNotCommunicatedSince',
  //       verticalIdentifier: 'Ctc_ErrorKey_HasNotCommunicatedSince',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 514,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 515,
  //       name: 'OLC is not reachable.',
  //       verticalIdentifier: 'Event_OlcNotReachableNew',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 515,
  //         contractId: 19,
  //         category: 'Leve',
  //       },
  //     },
  //     {
  //       id: 516,
  //       name: 'Ctc_Event_LampOutage',
  //       verticalIdentifier: 'Ctc_Event_LampOutage',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 516,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //     {
  //       id: 519,
  //       name: 'System detected Input mains grid voltage is either too low or too high. This might lead system failures',
  //       verticalIdentifier: 'Event_VoltageOutOfBoundsWarning',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 519,
  //         contractId: 19,
  //         category: 'Media',
  //       },
  //     },
  //     {
  //       id: 523,
  //       name: 'Ctc_Event_PhotocellHardwareError',
  //       verticalIdentifier: 'Ctc_Event_PhotocellHardwareError',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 523,
  //         contractId: 19,
  //         category: 'Crítica',
  //       },
  //     },
  //     {
  //       id: 524,
  //       name: 'Possible fault in the driver.',
  //       verticalIdentifier: 'Event_OneToTenPfFailure',
  //       deviceType: 'node',
  //       verticalId: 2,
  //       alarmTypeContract: {
  //         alarmTypeId: 524,
  //         contractId: 19,
  //         category: 'Grave',
  //       },
  //     },
  //   ],
  //   verticals: [
  //     {
  //       id: 2,
  //       name: 'Signify',
  //       contractModuleVertical: {
  //         contractId: 19,
  //         moduleId: 1,
  //         verticalId: 2,
  //         externalId: '91f92c2d-46c4-49bf-8705-450636789d9f',
  //       },
  //       alarmTypes: [
  //         {
  //           id: 89,
  //           name: 'Ctc_Event_ErrorInGPSCoordinate',
  //           verticalIdentifier: 'Ctc_Event_ErrorInGPSCoordinate',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 90,
  //           name: 'Battery mode',
  //           verticalIdentifier: 'issuetype_100',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 504,
  //           name: 'Row of lights out (three or more)',
  //           verticalIdentifier: 'IssueCategory_RowOfLightsOut',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 505,
  //           name: 'Light flashing',
  //           verticalIdentifier: 'IssueCategory_LightFlashing',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 506,
  //           name: 'Luminaire glass broken',
  //           verticalIdentifier: 'IssueCategory_LuminaireGlassBroken',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 507,
  //           name: 'Luminaire hanging off',
  //           verticalIdentifier: 'IssueCategory_LuminaireHangingOff',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 508,
  //           name: 'Door open',
  //           verticalIdentifier: 'IssueCategory_LightPoleDoorOpen',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 509,
  //           name: 'Pole or Housing damaged',
  //           verticalIdentifier: 'IssueCategory_LightPoleDamaged',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 510,
  //           name: 'Light pole knocked over',
  //           verticalIdentifier: 'IssueCategory_LightPoleKnockedOver',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 511,
  //           name: 'Wires exposed, risk of electric shock',
  //           verticalIdentifier: 'IssueCategory_ExposedWires',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 512,
  //           name: 'Other street lighting fault',
  //           verticalIdentifier: 'IssueCategory_OtherStreetLightingFault',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 517,
  //           name: 'Light switching failed',
  //           verticalIdentifier: 'issuetype_3750',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 518,
  //           name: 'Battery not connected',
  //           verticalIdentifier: 'issuetype_2414',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 520,
  //           name: 'Battery shutdown',
  //           verticalIdentifier: 'issuetype_101',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 521,
  //           name: 'Main power failure',
  //           verticalIdentifier: 'issuetype_3516',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 522,
  //           name: 'Undervoltage error phase L1',
  //           verticalIdentifier: 'issuetype_3511',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 525,
  //           name: 'Undervoltage error phase L3',
  //           verticalIdentifier: 'issuetype_3515',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 526,
  //           name: 'Undervoltage error phase L2',
  //           verticalIdentifier: 'issuetype_3513',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 527,
  //           name: 'ABUS overload',
  //           verticalIdentifier: 'issuetype_3201',
  //           deviceType: 'panel',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 513,
  //           name: 'The current measured is either lower than expected or no current is flowing through the driver-lamp combination.',
  //           verticalIdentifier: 'Event_NoCurrent',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 514,
  //           name: 'Ctc_ErrorKey_HasNotCommunicatedSince',
  //           verticalIdentifier: 'Ctc_ErrorKey_HasNotCommunicatedSince',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 515,
  //           name: 'OLC is not reachable.',
  //           verticalIdentifier: 'Event_OlcNotReachableNew',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 516,
  //           name: 'Ctc_Event_LampOutage',
  //           verticalIdentifier: 'Ctc_Event_LampOutage',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 519,
  //           name: 'System detected Input mains grid voltage is either too low or too high. This might lead system failures',
  //           verticalIdentifier: 'Event_VoltageOutOfBoundsWarning',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 523,
  //           name: 'Ctc_Event_PhotocellHardwareError',
  //           verticalIdentifier: 'Ctc_Event_PhotocellHardwareError',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //         {
  //           id: 524,
  //           name: 'Possible fault in the driver.',
  //           verticalIdentifier: 'Event_OneToTenPfFailure',
  //           deviceType: 'node',
  //           verticalId: 2,
  //         },
  //       ],
  //     },
  //     {
  //       id: 3,
  //       name: 'GIM',
  //       contractModuleVertical: {
  //         contractId: 19,
  //         moduleId: 1,
  //         verticalId: 3,
  //         externalId: '291827',
  //       },
  //       alarmTypes: [],
  //     },
  //     {
  //       id: 4,
  //       name: 'Dexcell',
  //       contractModuleVertical: {
  //         contractId: 19,
  //         moduleId: 3,
  //         verticalId: 4,
  //         externalId: '123',
  //       },
  //       alarmTypes: [],
  //     },
  //     {
  //       id: 5,
  //       name: 'Energiza',
  //       contractModuleVertical: {
  //         contractId: 19,
  //         moduleId: 4,
  //         verticalId: 5,
  //         externalId: '123',
  //       },
  //       alarmTypes: [],
  //     },
  //     {
  //       id: 1,
  //       name: 'Tellink',
  //       contractModuleVertical: {
  //         contractId: 19,
  //         moduleId: 1,
  //         verticalId: 1,
  //         externalId: '4',
  //       },
  //       alarmTypes: [
  //         {
  //           id: 1,
  //           name: 'Salida 1 relé ON',
  //           verticalIdentifier: '1',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 2,
  //           name: 'Salida 1 relé OFF',
  //           verticalIdentifier: '2',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 3,
  //           name: 'Equipo OFF',
  //           verticalIdentifier: '3',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 4,
  //           name: 'Equipo ON',
  //           verticalIdentifier: '4',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 5,
  //           name: 'Señal baja red móvil GSM',
  //           verticalIdentifier: '5',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 6,
  //           name: 'Sin datos red móvil GPRS OFF',
  //           verticalIdentifier: '6',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 7,
  //           name: 'Salida 2 relé ON',
  //           verticalIdentifier: '7',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 8,
  //           name: 'Error comunicación analizador',
  //           verticalIdentifier: '8',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 9,
  //           name: 'Entrada 1 on',
  //           verticalIdentifier: '9',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 10,
  //           name: 'Entrada 2 on',
  //           verticalIdentifier: '10',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 11,
  //           name: 'Entrada 3 on',
  //           verticalIdentifier: '11',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 12,
  //           name: 'Entrada 4 on',
  //           verticalIdentifier: '12',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 13,
  //           name: 'Corriente alta con relé OFF fase 1',
  //           verticalIdentifier: '13',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 14,
  //           name: 'Corriente alta con relé OFF fase 2',
  //           verticalIdentifier: '14',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 15,
  //           name: 'Corriente alta con relé OFF fase 3',
  //           verticalIdentifier: '15',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 16,
  //           name: 'Exceso de consumo en OFF',
  //           verticalIdentifier: '17',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 17,
  //           name: 'Exceso de consumo en ON',
  //           verticalIdentifier: '18',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 18,
  //           name: 'Factor de potencia bajo',
  //           verticalIdentifier: '19',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 19,
  //           name: 'Sin uso',
  //           verticalIdentifier: '20',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 20,
  //           name: 'Corriente baja con relé ON fase 1',
  //           verticalIdentifier: '21',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 21,
  //           name: 'Corriente baja con relé ON fase 2',
  //           verticalIdentifier: '22',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 22,
  //           name: 'Corriente baja con relé ON fase 3',
  //           verticalIdentifier: '23',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 23,
  //           name: 'Corriente alta con relé ON fase 1',
  //           verticalIdentifier: '24',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 24,
  //           name: 'Corriente alta con relé ON fase 2',
  //           verticalIdentifier: '25',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 25,
  //           name: 'Corriente alta con relé ON fase 3',
  //           verticalIdentifier: '26',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 26,
  //           name: 'Tensión alta fase 1',
  //           verticalIdentifier: '27',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 27,
  //           name: 'Tensión baja fase 1',
  //           verticalIdentifier: '28',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 28,
  //           name: 'Tensión alta fase 2',
  //           verticalIdentifier: '29',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 29,
  //           name: 'Tensión baja fase 2',
  //           verticalIdentifier: '30',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 30,
  //           name: 'Tensión alta fase 3',
  //           verticalIdentifier: '31',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 31,
  //           name: 'Tensión baja fase 3',
  //           verticalIdentifier: '32',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 32,
  //           name: 'Entrada 5 on',
  //           verticalIdentifier: '33',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 33,
  //           name: 'Entrada 6 on',
  //           verticalIdentifier: '34',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 34,
  //           name: 'Entrada 7 on',
  //           verticalIdentifier: '35',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 35,
  //           name: 'Entrada 8 on',
  //           verticalIdentifier: '36',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 36,
  //           name: 'Salida 3 relé OFF',
  //           verticalIdentifier: '37',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 37,
  //           name: 'Salida 3 relé ON',
  //           verticalIdentifier: '38',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 38,
  //           name: 'Error GPRS',
  //           verticalIdentifier: '39',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 39,
  //           name: 'Error AC In',
  //           verticalIdentifier: '40',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 40,
  //           name: 'Salida 4 relé OFF',
  //           verticalIdentifier: '41',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 41,
  //           name: 'Salida 4 relé ON',
  //           verticalIdentifier: '42',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 42,
  //           name: 'Error tabla relés',
  //           verticalIdentifier: '43',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 43,
  //           name: 'Error de reloj',
  //           verticalIdentifier: '44',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 49,
  //           name: 'Desconocido',
  //           verticalIdentifier: '16',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 50,
  //           name: 'PERFIL_ALARM_EDIFICIOS',
  //           verticalIdentifier:
  //             'Name: PERFIL_ALARM_EDIFICIOS, Contract: San Sebastián de los Reyes, idContract: 14',
  //           deviceType: 'panel',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 44,
  //           name: 'Tensión alta o máxima',
  //           verticalIdentifier: '1',
  //           deviceType: 'node',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 45,
  //           name: 'Tensión baja o mínima',
  //           verticalIdentifier: '2',
  //           deviceType: 'node',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 46,
  //           name: 'Corriente alta o máxima',
  //           verticalIdentifier: '3',
  //           deviceType: 'node',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 47,
  //           name: 'Corriente baja o mínima',
  //           verticalIdentifier: '4',
  //           deviceType: 'node',
  //           verticalId: 1,
  //         },
  //         {
  //           id: 48,
  //           name: 'Error GPRS, Error Driver',
  //           verticalIdentifier: '8',
  //           deviceType: 'node',
  //           verticalId: 1,
  //         },
  //       ],
  //     },
  //   ],
  //   alarmNotifications: [
  //     {
  //       id: 8,
  //       name: 'Notificaciones Graves 3',
  //       lastSended: '2023-05-15T08:00:00.048Z',
  //       isCritico: false,
  //       isGrave: true,
  //       isMedia: false,
  //       isLeve: false,
  //       isUndefined: false,
  //       numCritico: 0,
  //       numGrave: 3,
  //       numMedia: 0,
  //       numLeve: 0,
  //       numUndefined: 0,
  //       numHours: 24,
  //       contractId: 19,
  //       users: [
  //         {
  //           role: 'user',
  //           id: 42,
  //           email: 'jsutilfer@gmail.com',
  //           name: 'Jonathan',
  //           surnames: 'Sutil',
  //           phone: '633333333',
  //           company: 'Serveo',
  //           isAdmin: false,
  //           position: 'Pruebas',
  //           contractUsersNotification: {
  //             userId: 42,
  //             alarmNotificationId: 8,
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 9,
  //       name: 'Notificaciones leves 15',
  //       lastSended: '2023-05-12T13:00:00.100Z',
  //       isCritico: false,
  //       isGrave: false,
  //       isMedia: false,
  //       isLeve: true,
  //       isUndefined: false,
  //       numCritico: 0,
  //       numGrave: 0,
  //       numMedia: 0,
  //       numLeve: 15,
  //       numUndefined: 0,
  //       numHours: 48,
  //       contractId: 19,
  //       users: [
  //         {
  //           role: 'user',
  //           id: 42,
  //           email: 'jsutilfer@gmail.com',
  //           name: 'Jonathan',
  //           surnames: 'Sutil',
  //           phone: '633333333',
  //           company: 'Serveo',
  //           isAdmin: false,
  //           position: 'Pruebas',
  //           contractUsersNotification: {
  //             userId: 42,
  //             alarmNotificationId: 9,
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 10,
  //       name: 'Prueba Review',
  //       lastSended: '2023-05-12T08:05:25.848Z',
  //       isCritico: true,
  //       isGrave: false,
  //       isMedia: false,
  //       isLeve: false,
  //       isUndefined: false,
  //       numCritico: 3,
  //       numGrave: 0,
  //       numMedia: 0,
  //       numLeve: 0,
  //       numUndefined: 0,
  //       numHours: 24,
  //       contractId: 19,
  //       users: [
  //         {
  //           role: 'user',
  //           id: 33,
  //           email: 'gestor@serveo.com',
  //           name: 'Usuario gestor',
  //           surnames: 'gestor',
  //           phone: '822292299',
  //           company: 'Serveo',
  //           isAdmin: false,
  //           position: 'Gestor',
  //           contractUsersNotification: {
  //             userId: 33,
  //             alarmNotificationId: 10,
  //           },
  //         },
  //       ],
  //     },
  //     {
  //       id: 11,
  //       name: 'Prueba Review2',
  //       lastSended: '2023-05-15T08:00:00.048Z',
  //       isCritico: true,
  //       isGrave: true,
  //       isMedia: false,
  //       isLeve: false,
  //       isUndefined: false,
  //       numCritico: 2,
  //       numGrave: 1,
  //       numMedia: 0,
  //       numLeve: 0,
  //       numUndefined: 0,
  //       numHours: 24,
  //       contractId: 19,
  //       users: [
  //         {
  //           role: 'user',
  //           id: 33,
  //           email: 'gestor@serveo.com',
  //           name: 'Usuario gestor',
  //           surnames: 'gestor',
  //           phone: '822292299',
  //           company: 'Serveo',
  //           isAdmin: false,
  //           position: 'Gestor',
  //           contractUsersNotification: {
  //             userId: 33,
  //             alarmNotificationId: 11,
  //           },
  //         },
  //       ],
  //     },
  //   ],
  //   modules: [
  //     {
  //       contractId: 19,
  //       moduleId: 3,
  //       verticalId: 2,
  //       externalId: '91f92c2d-46c4-49bf-8705-450636789d9f',
  //       module: {
  //         id: 3,
  //         name: 'Gestión energética',
  //       },
  //       vertical: {
  //         id: 2,
  //         name: 'Signify',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 1,
  //       verticalId: 2,
  //       externalId: '91f92c2d-46c4-49bf-8705-450636789d9f',
  //       module: {
  //         id: 1,
  //         name: 'Alumbrado',
  //       },
  //       vertical: {
  //         id: 2,
  //         name: 'Signify',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 2,
  //       verticalId: 3,
  //       externalId: '291827',
  //       module: {
  //         id: 2,
  //         name: 'Gestión de activos',
  //       },
  //       vertical: {
  //         id: 3,
  //         name: 'GIM',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 1,
  //       verticalId: 3,
  //       externalId: '291827',
  //       module: {
  //         id: 1,
  //         name: 'Alumbrado',
  //       },
  //       vertical: {
  //         id: 3,
  //         name: 'GIM',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 3,
  //       verticalId: 4,
  //       externalId: '123',
  //       module: {
  //         id: 3,
  //         name: 'Gestión energética',
  //       },
  //       vertical: {
  //         id: 4,
  //         name: 'Dexcell',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 4,
  //       verticalId: 5,
  //       externalId: '123',
  //       module: {
  //         id: 4,
  //         name: 'Gestión de facturación',
  //       },
  //       vertical: {
  //         id: 5,
  //         name: 'Energiza',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 3,
  //       verticalId: 1,
  //       externalId: '4',
  //       module: {
  //         id: 3,
  //         name: 'Gestión energética',
  //       },
  //       vertical: {
  //         id: 1,
  //         name: 'Tellink',
  //       },
  //     },
  //     {
  //       contractId: 19,
  //       moduleId: 1,
  //       verticalId: 1,
  //       externalId: '4',
  //       module: {
  //         id: 1,
  //         name: 'Alumbrado',
  //       },
  //       vertical: {
  //         id: 1,
  //         name: 'Tellink',
  //       },
  //     },
  //   ],
  //   removedFeatures: [
  //     {
  //       userId: 31,
  //       contractId: 19,
  //       featureId: 5,
  //       user: {
  //         role: 'user',
  //         id: 31,
  //         email: 'cliente@serveo.com',
  //         name: 'Usuario cliente',
  //         surnames: 'cliente',
  //         phone: '677785455',
  //         company: 'Serveo',
  //         isAdmin: false,
  //         position: 'Cliente',
  //       },
  //       removedFeature: {
  //         id: 5,
  //         name: 'Alertas',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 31,
  //       contractId: 19,
  //       featureId: 4,
  //       user: {
  //         role: 'user',
  //         id: 31,
  //         email: 'cliente@serveo.com',
  //         name: 'Usuario cliente',
  //         surnames: 'cliente',
  //         phone: '677785455',
  //         company: 'Serveo',
  //         isAdmin: false,
  //         position: 'Cliente',
  //       },
  //       removedFeature: {
  //         id: 4,
  //         name: 'Programación de equipos',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 31,
  //       contractId: 19,
  //       featureId: 3,
  //       user: {
  //         role: 'user',
  //         id: 31,
  //         email: 'cliente@serveo.com',
  //         name: 'Usuario cliente',
  //         surnames: 'cliente',
  //         phone: '677785455',
  //         company: 'Serveo',
  //         isAdmin: false,
  //         position: 'Cliente',
  //       },
  //       removedFeature: {
  //         id: 3,
  //         name: 'Detalles',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 31,
  //       contractId: 19,
  //       featureId: 2,
  //       user: {
  //         role: 'user',
  //         id: 31,
  //         email: 'cliente@serveo.com',
  //         name: 'Usuario cliente',
  //         surnames: 'cliente',
  //         phone: '677785455',
  //         company: 'Serveo',
  //         isAdmin: false,
  //         position: 'Cliente',
  //       },
  //       removedFeature: {
  //         id: 2,
  //         name: 'Histórico',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 34,
  //       contractId: 19,
  //       featureId: 5,
  //       user: {
  //         role: 'user',
  //         id: 34,
  //         email: 'usuariocliente@gmail.com',
  //         name: 'usuario cliente',
  //         surnames: 'usuario cliente',
  //         phone: '619283746',
  //         company: 'ElliotCloud',
  //         isAdmin: false,
  //         position: 'Developer',
  //       },
  //       removedFeature: {
  //         id: 5,
  //         name: 'Alertas',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 34,
  //       contractId: 19,
  //       featureId: 4,
  //       user: {
  //         role: 'user',
  //         id: 34,
  //         email: 'usuariocliente@gmail.com',
  //         name: 'usuario cliente',
  //         surnames: 'usuario cliente',
  //         phone: '619283746',
  //         company: 'ElliotCloud',
  //         isAdmin: false,
  //         position: 'Developer',
  //       },
  //       removedFeature: {
  //         id: 4,
  //         name: 'Programación de equipos',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 34,
  //       contractId: 19,
  //       featureId: 3,
  //       user: {
  //         role: 'user',
  //         id: 34,
  //         email: 'usuariocliente@gmail.com',
  //         name: 'usuario cliente',
  //         surnames: 'usuario cliente',
  //         phone: '619283746',
  //         company: 'ElliotCloud',
  //         isAdmin: false,
  //         position: 'Developer',
  //       },
  //       removedFeature: {
  //         id: 3,
  //         name: 'Detalles',
  //         moduleId: 1,
  //       },
  //     },
  //     {
  //       userId: 34,
  //       contractId: 19,
  //       featureId: 2,
  //       user: {
  //         role: 'user',
  //         id: 34,
  //         email: 'usuariocliente@gmail.com',
  //         name: 'usuario cliente',
  //         surnames: 'usuario cliente',
  //         phone: '619283746',
  //         company: 'ElliotCloud',
  //         isAdmin: false,
  //         position: 'Developer',
  //       },
  //       removedFeature: {
  //         id: 2,
  //         name: 'Histórico',
  //         moduleId: 1,
  //       },
  //     },
  //   ],
  // });

  /**
   * Getter to get contract
   */
  get contractObservable() {
    return this.contract$.asObservable();
  }

  /**
   * Setter to emit new contract
   */
  set contractObservableNext(contract: IUserContract) {
    this.contract$.next(contract);
  }
}
